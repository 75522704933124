<template>
    <div>
        <p>{{ brandprofileAlias }}</p>
        <p class="text-caption">{{ accountId }}</p>
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        brandprofileAlias() {
            return this.attr.brandprofile_alias;
        },
        accountId() {
            return this.attr.account_id;
        },
    },

};
</script>
