<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">BrandProfile Aliases</h1>
                <!-- <p class="text-caption text-center">BrandProfile Aliases</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <BrandProfileList/> <!--  @selected="onSelectBrandProfile" -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import BrandProfileList from '@/components/service-dashboard/BrandProfileList.vue';

export default {
    components: {
        BrandProfileList,
    },
    data: () => ({
        // account: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    methods: {
        // async loadBrandProfileAliasList() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { loadBrandProfileAliasList: true });
        //         const response = await this.$client.main().brandProfile.search();
        //         console.log(`SearchForm.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.account = response;
        //         } else {
        //             // TODO: redirect back to account list? show a not found message?
        //         }
        //     } catch (err) {
        //         console.error('failed to load account', err);
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { loadBrandProfileAliasList: false });
        //     }
        // },
        // onSelectBrandProfile(item) {
        //     console.log(`onSelectBrandProfile: ${JSON.stringify(item)}`);
        //     // this.$router.push({ name: 'account-edit-email-audience', params: { accountId: this.$route.params.accountId, brandProfileId: id } });
        // },
    },
    mounted() {
        // this.loadBrandProfileAliasList();
    },
};
</script>
