<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="teal darken-2" @click="openCreateBrandProfileAliasDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="teal darken-2" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="green--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No brand profiles yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item)">
                <v-list-item-content class="text-start">
                    <!-- <BrandProfileAliasListItem :attr="item" :link="!selectOne"></BrandProfileAliasListItem> -->
                    <BrandProfileAliasListItem :attr="item"></BrandProfileAliasListItem>
                </v-list-item-content>
                <!-- <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="teal darken-2" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'account-delete-email-audience', params: { accountId, brandProfileAliasId: item.id } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                         </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action> -->
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateBrandProfileAlias">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-template v-model="createBrandProfileAlias" ref="createBrandProfileAliasRef" @submit="validateCreateAudience" onSubmit="return false;" @keyup.enter.native="validateCreateAudience">
                    <div v-if="add">
                        <v-select :items="addBrandProfileAliasChoices" v-model="addBrandProfileAliasId" label="Select an template"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newAudienceName
                            label="Display Name"
                            :rules="newAudienceNameRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            hint="The name for the template"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addAudience" :disabled="!createBrandProfileAlias" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createAudience" :disabled="!createBrandProfileAlias" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-template>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateAudienceDialog v-model="createBrandProfileDialog" @cancel="createBrandProfileDialog = false" @created="createBrandProfileDialog = false"/> -->
        <v-dialog v-model="createBrandProfileDialog" max-width="600">
            <v-card elevation="4"> <!--  style="border: solid 1px red;" -->
                <v-toolbar class="teal white--text" dense flat>
                    <v-toolbar-title>Assign BrandProfile to account</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-form>
                        <v-text-field
                            label="BrandProfile Alias"
                            v-model="editableBrandProfileAlias"
                            outlined
                            dense
                        >
                        </v-text-field>
                        <v-text-field
                            label="Account ID"
                            v-model="editableAccountId"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <!-- <v-row justify="center" class="pt-3">
                    <v-col cols="12">
                        <p class="font-weight-light text-body-1">This action is not reversible.</p>
                        <p class="text-body-1 font-weight-light pb-2 red--text">All form data will be permanently deleted.</p>
                    </v-col>
                </v-row> -->
                <v-card-actions>
                    <v-btn elevation="4" class="teal white--text" @click="createBrandProfile" :disabled="isCreateBrandProfileDisabled">
                        Assign
                    </v-btn>
                    <v-btn text class="grey--text" @click="createBrandProfileDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editBrandProfileDialog" max-width="600">
            <v-card elevation="4"> <!--  style="border: solid 1px red;" -->
                <v-toolbar class="teal white--text" dense flat>
                    <v-toolbar-title>Re-assign BrandProfile to a different account</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-form>
                        <v-text-field
                            label="BrandProfile Alias"
                            v-model="editableBrandProfileAlias"
                            outlined
                            dense
                            disabled
                        >
                        </v-text-field>
                        <v-text-field
                            label="Account ID"
                            v-model="editableAccountId"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <!-- <v-row justify="center" class="pt-3">
                    <v-col cols="12">
                        <p class="font-weight-light text-body-1">This action is not reversible.</p>
                        <p class="text-body-1 font-weight-light pb-2 red--text">All form data will be permanently deleted.</p>
                    </v-col>
                </v-row> -->
                <v-card-actions>
                    <v-btn elevation="4" class="teal white--text" @click="editBrandProfile" :disabled="isEditBrandProfileDisabled">
                        Assign
                    </v-btn>
                    <v-btn text class="grey--text" @click="editBrandProfileDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import BrandProfileAliasListItem from '@/components/list-item/BrandProfileAliasListItem.vue';
// import CreateAudienceDialog from '@/components/account-dashboard/CreateAudienceDialog.vue';
// import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        BrandProfileAliasListItem,
        // CreateAudienceDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the audience; maybe create a different component for "select a audience"
        selectOne: false,
        add: false,
        create: true,

        list: [],

        createBrandProfileDialog: false,
        editBrandProfileDialog: false,

        editableBrandProfileAlias: null,
        editableAccountId: null,

        // create audience
        newBrandProfileAliasRules: [
            (v) => !!v || 'BrandProfile alias is required',
            // (v) => !v || isValidName(compact(v)) || 'BrandProfile alias is required',
        ],
        // addBrandProfileAliasChoices: [],
        // addBrandProfileAliasId: null,
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            if (this.selectOne) {
                return 'Select a brand profile';
            }
            switch (this.list.length) {
            case 0:
                return 'No brand profiles';
            case 1:
                return '1 brand profile';
            default:
                return `${this.list.length} brand profiles`;
            }
        },
        isCreateBrandProfileDisabled() {
            return !this.editableBrandProfileAlias || !this.editableAccountId;
        },
        isEditBrandProfileDisabled() {
            return !this.editableBrandProfileAlias || !this.editableAccountId;
        },
    },

    watch: {
        displayCreateBrandProfileAlias(value) {
            if (value && (this.add || this.create)) {
                this.initAddBrandProfileAliasChoices();
            }
        },
    },

    methods: {
        async loadBrandProfileAliasList() {
            try {
                this.$store.commit('loading', { loadBrandProfileAliasList: true });
                const match = {};
                if (typeof this.editableBrandProfileAlias === 'string') {
                    match.brandprofile_alias = this.editableBrandProfileAlias;
                }
                if (typeof this.editableAccountId === 'string') {
                    match.account_id = this.editableAccountId;
                }
                const response = await this.$client.main().brandProfile.search(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadBrandProfileAliasList failed', err);
            } finally {
                this.$store.commit('loading', { loadBrandProfileAliasList: false });
            }
        },
        onClickItem(item) {
            // const { brandprofile_alias, account_id } = item;
            // this.$emit('selected', item);
            this.editableBrandProfileAlias = item.brandprofile_alias;
            this.editableAccountId = item.account_id;
            this.editBrandProfileDialog = true;
        },
        openCreateBrandProfileAliasDialog() {
            this.editableBrandProfileAlias = null;
            this.editableAccountId = null;
            this.createBrandProfileDialog = true;
        },
        async createBrandProfile() {
            try {
                this.error = false;
                this.$store.commit('loading', { createBrandProfile: true });
                console.log('createBrandProfile');
                const request = {
                    brandprofile_alias: this.editableBrandProfileAlias,
                    account_id: this.editableAccountId,
                };
                const response = await this.$client.main().brandProfile.create(request);
                console.log('createBrandProfile response: %o', response);
                const { isCreated, error } = response;
                if (isCreated) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.createBrandProfileDialog = false;
                    this.loadBrandProfileAliasList(); // TODO: maybe just add it to the list directly?
                    // // const form = { id, ...request };
                    // // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    // this.$router.replace({ name: 'account-edit-email-audience', params: { accountId: this.$route.params.accountId, brandProfileId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to assign BrandProfile alias to account' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to assign BrandProfile alias to account' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to assign BrandProfile alias to account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to assign BrandProfile alias to account' });
            } finally {
                this.$store.commit('loading', { createBrandProfile: false });
            }
        },
        async editBrandProfile() {
            try {
                this.error = false;
                this.$store.commit('loading', { editBrandProfile: true });
                console.log('editBrandProfile');
                const response = await this.$client.main().brandProfile.edit({ brandprofile_alias: this.editableBrandProfileAlias }, { account_id: this.editableAccountId });
                console.log('editBrandProfile response: %o', response);
                const { isEdited, error } = response;
                if (isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.editBrandProfileDialog = false;
                    this.loadBrandProfileAliasList(); // TODO: maybe just add it to the list directly?
                    // // const form = { id, ...request };
                    // // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    // this.$router.replace({ name: 'account-edit-email-audience', params: { accountId: this.$route.params.accountId, brandProfileId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to assign BrandProfile alias to account' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to assign BrandProfile alias to account' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to assign BrandProfile alias to account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to assign BrandProfile alias to account' });
            } finally {
                this.$store.commit('loading', { editBrandProfile: false });
            }
        },
        // async createAudience() {
        //     this.error = false;
        //     console.log('createAudience');
        //     const request = {
        //         // name: this.name,
        //         name: this.newAudienceName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if audience arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the audience to an appropriate location after audience is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).site.create(request);
        //     console.log('createAudience response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const audience = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', audience);
        //         this.$emit('added-site', audience);
        //         this.displayCreateBrandProfileAlias = false;
        //         this.newAudienceName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateAudience() {
        //     if (this.$refs.createBrandProfileAliasRef.validate()) {
        //         this.createAudience();
        //     }
        // },

        // async addAudience() {
        //     const audience = { id: this.addBrandProfileAliasId, label: this.addBrandProfileAliasChoices.find((item) => item.value === this.addBrandProfileAliasId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', audience);
        //     this.displayCreateBrandProfileAlias = false;
        //     this.addBrandProfileAliasId = null;
        // },
        // async initAddAudienceChoices() {
        //     // create a map of which audience ids are already in the list
        //     const audienceMap = {};
        //     this.list.forEach((item) => {
        //         audienceMap[item.id] = true;
        //     });
        //     // when we show the add/create audience dialog, load the list of available brand profiles to add to the policy (list of all brand profiles less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).site.list();
        //     console.log(`AudienceViewList.vue fetch brand profiles result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove brand profiles already in the policy, then convert the available brand profiles to a choice list for the v-select [ { text, value }, ... ]
        //         this.addBrandProfileAliasChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadBrandProfileAliasList();
    },
};
</script>
